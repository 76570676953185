import React from 'react';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBriefcase, faTools, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import Resume from './Resume';
import { Route, Redirect, BrowserRouter } from 'react-router-dom';
import NavBar from './NavBar';
import Home from './Home';

library.add(faBriefcase, faTools, faGraduationCap);

export default class App extends React.Component {

  render() {
    document.title = "Sidhant Goel";
  return (
    <div>
      <NavBar />
      <BrowserRouter>
        <Route exact path="/home" component={Home} />
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/resume" component={Resume} />
      </BrowserRouter>
    </div>
  );
  }
}


