import React from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBriefcase, faTools, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

library.add(faBriefcase, faTools, faGraduationCap);

export default class NavBar extends React.Component {

  render() {
  return (
<Navbar bg="light" expand="lg">
  <Navbar.Brand href="#home">Sidhant Goel</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/home">Home</Nav.Link>
      <Nav.Link href="/resume">Resume</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
  );
  }
}


