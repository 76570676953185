import React from 'react';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBriefcase, faTools, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

library.add(faBriefcase, faTools, faGraduationCap);

export default class Home extends React.Component {

  render() {
  return (
      <div />
  );
  }
}


