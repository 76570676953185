import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBriefcase, faTools, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

library.add(faBriefcase, faTools, faGraduationCap);

export default class Resume extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      work: {},
      projects: {}
    };
  }

  componentDidMount() {
    fetch("https://spreadsheets.google.com/feeds/list/1B7o0o8mUh-v5Q-7oo5w-ZYxshDt0zxCiwm45N9SYCHk/od6/public/values?alt=json")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            work: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    fetch("https://spreadsheets.google.com/feeds/list/1B7o0o8mUh-v5Q-7oo5w-ZYxshDt0zxCiwm45N9SYCHk/oh8388q/public/values?alt=json")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            projects: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
  return (
    
    <div className="Resume">
<article className="header">
      <div className="header-left"><span>sidhant@hexcod.in<br />+91-7406099969</span></div>
      <div className="header-separator"></div>
      <div className="header-content">
      <h2>Sidhant Goel</h2>
      <h3>Engineer, Maker, Explorer, Researcher</h3>
      </div>
    </article>
    <div className="section-separator"><FontAwesomeIcon icon="briefcase" size="2x" transform="down-2" /> <span>WORK</span></div>
<div className="timeline">
  {Object.keys(this.state.work).length !== 0 && this.state.work.feed.entry.reverse().map((item, key) => 
    <article className="timeline-entry">
      <div className="timeline-time"><span>{item.gsx$from['$t']} - {item.gsx$to['$t']}</span></div>
      <div className="timeline-separator"></div>
      <div className="timeline-content">
      <h2>{item.gsx$title['$t']}</h2>
      <h3>{item.gsx$designation['$t']}</h3>
      <p>{item.gsx$description['$t']}</p>
      <p>
        {item.gsx$skills['$t'].split(", ").map((item, key) =>
            <><span className="badge badge-tags">{item}</span> </>
        )}
        </p>
      </div>
    </article>
  )}
			</div>
      <div className="section-separator2"><div className="separator2-head"><FontAwesomeIcon icon="tools" size="2x" transform="down-2" /> <span>PROJECTS</span></div></div>
      <div className="timeline">
      {Object.keys(this.state.projects).length !== 0 && this.state.projects.feed.entry.reverse().filter((item, key) => key%2 === 0).map((item, key) => 
    <article className="timeline-entry">
      <div className="timeline-content timeline-content-left">
      <h2>{item.gsx$title['$t']}</h2>
      <p>
        {item.gsx$skills['$t'].split(", ").map((item, key) =>
            <><span className="badge badge-tags">{item}</span> </>
        )}
        </p>
      </div>
      <div className="timeline-separator"></div>
      <div className="timeline-content">
        {key + 1 < this.state.projects.feed.entry.length &&
        <>
      <h2>{this.state.projects.feed.entry[key+1].gsx$title['$t']}</h2>
      <p>
        {this.state.projects.feed.entry[key+1].gsx$skills['$t'].split(", ").map((item, key) =>
            <><span className="badge badge-tags">{item}</span> </>
        )}
        </p>
        </>
        }
      </div>
    </article>

    
  )}
  </div>
  <div className="section-separator"><FontAwesomeIcon icon="graduation-cap" size="2x" transform="down-2" /> <span>EDUCATION</span></div>
  <div className="timeline">
    <article className="timeline-entry">
      <div className="timeline-time"><span>2008 - 2012</span></div>
      <div className="timeline-separator"></div>
      <div className="timeline-content">
      <h2>Bachelors of Technology (Dropout)</h2>
      <h3>Computer Science Engineering</h3>
      <p></p>
      </div>
    </article>
    <article className="timeline-entry">
      <div className="timeline-time"><span>2007 - 2008</span></div>
      <div className="timeline-separator"></div>
      <div className="timeline-content">
      <h2>12th</h2>
      <h3>Science</h3>
      <p></p>
      </div>
    </article>
    <article className="timeline-entry">
      <div className="timeline-time"><span>2005 - 2006</span></div>
      <div className="timeline-separator"></div>
      <div className="timeline-content">
      <h2>10th</h2>
      <h3>&nbsp;</h3>
      <p></p>
      </div>
    </article>
			</div>
    </div>
  );
  }
}


